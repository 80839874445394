<template>
    <div class="topbottom_padding">
        <div class="container" v-if="expdata">
            <div class="heading_block flex">
                <a class="backbtn
" href="#" @click.prevent="$router.go(-1)" />
                <h1>
                    {{
                        expdata.type.charAt(0).toUpperCase() +
                        expdata.type.slice(1)
                    }}
                </h1>
            </div>

            <div class="event_details_block">
                <!--Block-->
                <div class="block">
                    <ul class="list-group">
                        <li class="list-group-item">
                            {{ expdata.type == 'events' ? 'Event:' : 'Title:' }}
                            <span class="text-muted">{{ expdata.title }}</span>
                        </li>

                        <li
                            class="list-group-item"
                            v-if="expdata.type == 'offers'"
                        >
                            When:
                            <span class="text-muted">{{
                                expdata.recurrence_settings
                            }}</span>
                        </li>

                        <li
                            class="list-group-item"
                            v-if="expdata.type == 'events'"
                        >
                            When:
                            <span class="text-muted">{{
                                moment(expdata.occurence_dates).format(
                                    'ddd DD MMM, YY'
                                ) +
                                ' at ' +
                                expdata.start_time
                            }}</span>
                        </li>

                        <li
                            class="list-group-item"
                            v-if="expdata.type == 'promotions'"
                        >
                            Upcoming:
                            <span class="text-muted">{{
                                moment(expdata.occurence_dates).format(
                                    'ddd DD MMM, YY'
                                ) +
                                ' at ' +
                                expdata.start_time
                            }}</span>
                        </li>
                        <li
                            class="list-group-item"
                            v-if="expdata.type == 'promotions'"
                        >
                            When:
                            <span class="text-muted">{{
                                expdata.recurrence_settings +
                                ' at ' +
                                expdata.start_time
                            }}</span>
                        </li>
                        <li
                            class="list-group-item"
                            v-if="
                                expdata.type == 'promotions' ||
                                expdata.type == 'offers'
                            "
                        >
                            Available:
                            <span class="text-muted">{{
                                'From ' +
                                moment(expdata.start_date).format(
                                    'DD MMM, YYYY'
                                ) +
                                ' To ' +
                                moment(expdata.end_date).format('DD MMM, YYYY')
                            }}</span>
                        </li>

                        <li
                            class="list-group-item"
                            v-if="expdata.type == 'offers' && expdata.offer_attributes"
                        >
                            Promo Code:
                            <span class="text-muted">{{
                                expdata.offer_attributes.promo_code
                            }}</span>
                        </li>

                        <li
                            class="list-group-item"
                            v-if="expdata.type == 'offers' && expdata.offer_attributes"
                        >
                            Discount:
                            <span class="text-muted">{{
                                expdata.offer_attributes.discount_value
                            }}</span>
                        </li>

                        <li
                            class="list-group-item"
                            v-if="expdata.type == 'events'"
                        >
                            Event Fee:<span class="text-muted">{{
                                expdata.event_attributes.price
                                    ? '$' + expdata.event_attributes.price
                                    : 'FREE'
                            }}</span>
                        </li>
                        <li
                            class="list-group-item"
                            v-if="
                                expdata.type == 'events' &&
                                expdata.event_attributes.total_num_spaces
                            "
                        >
                            Space Available:
                            <span class="text-muted">
                                {{
                                    expdata.event_attributes.total_num_spaces -
                                    expdata.event_attributes.booked_num_spaces
                                }}
                                (out of
                                {{
                                    expdata.event_attributes.total_num_spaces
                                }}).</span
                            >
                        </li>
                    </ul>

                    <!-- <ul class="list-group">
                        <li
                            class="list-group-item"
                            v-if="expdata.type == 'events'"
                        >
                            Set an event reminder?
                            <div class="form-group">
                                <div class="dropdown_select">
                                    <b-form-select
                                        text-field="label"
                                        value-field="value_in_hours"
                                        :options="saveReminderTimeSettings"
                                        @input="setReminder"
                                        v-model="reminderHour"
                                    />
                                </div>
                            </div>
                        </li>
                    </ul> -->
                    <div
                        class="white_round_block"
                        v-if="expdata.type == 'events'"
                    >
                        <div class="block">
                            <h3>Set an event reminder?</h3>
                            <div class="form-group">
                                <div class="dropdown_select">
                                    <b-form-select
                                        text-field="label"
                                        class="form-control"
                                        value-field="value_in_hours"
                                        :options="saveReminderTimeSettings"
                                        @input="setReminder"
                                        v-model="reminderHour"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--End Block-->
                <!--Block-->
                <div class="block infotext">
                    <!-- <p>
                        The first Friday Flash draw held at 9pm and if
                        unclaimed, another will be held at 10pm.
                    </p>
                    <p>
                        If unclaimed, the prize pool jackpots $500 every week!
                        You must be here to claim the cash!
                    </p> -->
                    <p><span v-html="expdata.description" /></p>
                    <a
                        href=""
                        @click.prevent="attendEvent"
                        class="btn"
                        v-show="expdata.type == 'events'"
                        :disabled="isAttending"
                        v-if="!isAttending"
                    >
                        ATTEND THIS EVENT
                    </a>
                    <a
                        href=""
                        @click.prevent="() => {}"
                        class="btn_tick"
                        v-show="expdata.type == 'events'"
                        :disabled="isAttending"
                        v-else
                    >
                        ATTENDING THIS EVENT
                    </a>
                </div>
                <!--End Block-->
            </div>
        </div>

        <b-modal
            v-if ="expdata.type === 'events'"
            id="paymentmodal"
            v-model="displayModal"
            hide-footer
            hide-header
            body-class="p-0"
        >
            <div class="modal-content">
                <div class="modal-header">
                    <button
                        @click="hideModal"
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="verification_block">
                        <h2>Event Payment</h2>
                        <p>
                            Would you like to pay for this event now?<br />
                            You will be directed to a secure <br />
                            third party website for payment.
                        </p>
                        <div class="block">
                            <a
                                :href="expdata.event_attributes.booking_url"
                                class="btn"
                                >PAY
                                {{
                                    expdata.event_attributes.price
                                        ? '$' + expdata.event_attributes.price
                                        : ''
                                }}
                                NOW</a
                            >
                            <br />
                            <p>
                                or you can pay later<br />
                                <a @click="hideModal" style="cursor: pointer"
                                    >CLOSE & PAY LATER</a
                                >
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<style scoped>
.flex {
    display: flex;
}
.flex h1 {
    width: 100%;
}
</style>

<script>
export default {
    data() {
        return {
            id: 0,
            expdata: null,
        }
    },
    created() {
        this.id = parseInt(this.$route.params.id, 10);
        this.fetchData();
    },
    methods: {
        fetchData() {
            this.$http.post('offer-details', {
                offer_id: this.id,
            }).then(resp => {
                if(resp.data.status) {
                    this.expdata = resp.data.response_data;
                    this.expdata.type = 'offers';
                }
            }).catch(e => {
                console.error(e);
            }) 
        }
    },
}
</script>